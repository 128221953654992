@import "variables.scss";
@import "~bulma/bulma";
@font-face {
  font-family: Acumin;
  src: url("/fonts/AcuminVariableConcept.otf") format("opentype");
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 1000px;
  }
}

@media screen and (min-width: 1100px) {
  .container {
    max-width: 1080px;
  }
}

@media screen and (min-width: 1408px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1382px;
  }
}

body {
  font-size: 14px;
  font-family: 'Acumin', sans-serif;
  color: #000000;
  line-height: 24px;
}

.title, .subtitle {
  color: #000000;
  word-break: keep-all;
}

.alternate-title {
  font-family: 'Acumin', serif;
}

.main-app-container {
  z-index: $baseZIndex + 3;
}

.port-hope-banner {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #3D3F5D;
  z-index: 11;
  font-size: 14px;
  line-height: 17px;
  color: #BCBED9;
  text-align: center;
  padding: 17px 30px;

  span {
    color: #FFFFFF;
    font-weight: 700;
  }

  a {
    color: #FFAE25;
    font-weight: 700;
    text-decoration: underline;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }
  }
}

.summary-section {
  position: sticky;
  top: 60px;
  display: inline-block;
  background-color: #272739;
  max-height: 730px;
  padding: 35px 60px;
  color: #ffffff;
  border-radius: 20px;

  @media (max-width: 1024px) {
    top: 1rem;
  }

  &.donation-page {
    display: none;

    .donate {
      button {
        background-color: #ffffff !important;
        border-color: #ffffff !important;
        color: #E07A5F !important;
        height: 50px;

        &:hover {
          background-color: #E07A5F !important;
          color: #ffffff !important;
        }

        &[type="submit"] {
          background-color: transparent !important;
        }
      }
    }
  }

  &.newsletter{
    .newsletter-wrapper {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 100px;
      flex-wrap: wrap;

      input[type="text"] {
        background-color: transparent;
        font-size: 15px;
        padding: 20px 40px 22px 5px;
        color: #FFBE5F;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #F4F1DE;
        width: 65%;
        @media (max-width: 1100px) {
          width: 100%;
        }

        &::placeholder {
          color: #f4f1de73;
        }
      }

      .newsletter-button{
        border: 2px solid #ffffff;
        padding-top: 10px;
        
        @media (max-width: 1100px) {
          width: 100%;
          margin-top: 20px !important;
        }
      }

      .newsletter-notification {
        align-self: center;    
      }
    }

    .left-footer {
      height: 110px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      width: 100%;
      margin: 0;
      margin-bottom: 0px !important;
      padding: 40px 60px 40px 60px !important;
      padding: 0;
      bottom:0;
      left: 0;
      background-color: transparent;
      border-top: 1px solid #B46C55;
      @media (max-width: 1100px) {
        padding: 20px 60px 40px 60px !important;
        flex-direction: column;
      }

      .make-donation-button {
        color: white;
        padding: 25px 15px;
      }

      ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        @media (max-width: 1100px) {
          margin-top: 10px;
        }
        
        li {
          float: left;
          padding-left: 20px;
          line-height: 0;
          a {
            display: block;
            color: white;
            text-align: center;
            text-decoration: none;
          }
        }
        li:first-child {
          font-weight: bold;
          @media (max-width: 1100px) {
            padding-left:0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    padding: 30px;
  }

  .back-arrow {
    cursor: pointer;
  }

  .main-title {
    color: #FFF15F;
    font-family: "Acumin", sans-serif;
    font-weight: 700;
    margin-top: 3rem;
  }

  .subtitle {
    color: #ffffff;
    font-size: 2.8125rem;
    line-height: calc(50/45);
    font-weight: bold;
  }

  button {
    font-size: 18px !important;
    font-weight: bold;
  }

  .other-amount-wrapper {
    position: relative;
    padding: 4px 2px;
    border-bottom: 1px solid #F4F1DE;

    &:before {
      content: "$";
      position: absolute;
      top: 4px;
      left: 0;
      font-size: 15px;
    }

    input[type="number"] {
      background-color: transparent;
      font-size: 15px;
      padding: 14px 40px 22px 14px;
      color: #FFBE5F;
      border: 0;

      &::placeholder {
        color: #f4f1de73;
      }
    }

    .other-amount-button {
      position: absolute;
      top: -2px;
      right: 0;
    }
  }

  > *:not(.bgs-container) {
    position: relative;
    z-index: 2;
  }

  .bgs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 20px;
  }

  .bg {
    position: absolute;
    top: -10px;
    left: 0;
    right: -10px;
    bottom: 0;
    transition: opacity 1000ms;
    opacity: 0;
    z-index: 1;
    background-size: cover;
    background-position: top right;

    &.bg-1 {
      background: linear-gradient(218.51deg, #ECA55D 0%, #E07A5F 40%);
    }

    &.bg-2 {
      background: linear-gradient(218.51deg, #ECA55D 0%, #7FB098 40%);
    }

    &.bg-3 {
      background: linear-gradient(218.51deg, #ECA55D 0%, #8281B2 40%);
    }

    &.bg-4 {
      background: linear-gradient(218.51deg, #ECA55D 0%, #E46E89 40%);
    }
  }

  // Variants of the summary pages based on the currently displayed page
  &.summary-page-1 {
    // background-image: radial-gradient(at 100% 0%, #E07A5F 0%, transparent 40%);
    // transition: background-image $summaryPageColorTransitionDuration linear;

    .bg-1 {
      opacity: 1;
    }

    .button {
      @extend .is-danger;
      transition: background-color 1000ms linear, border-color 250ms linear;
    }

    .newsletter-button {
      background-color: white;
      color: $danger;
    }
  }

  &.summary-page-2 {
    // background-image:
    //   radial-gradient(at 100% 0%, rgba(255,190,95,1) 0%, transparent 20%),
    //   radial-gradient(at 100% 0%, #7FB098 0%, transparent 50%);
    // transition: background-image $summaryPageColorTransitionDuration linear;

    .bg-2 {
      opacity: 1;
    }

    .button {
      @extend .is-success;
      transition: background-color $summaryPageColorTransitionDuration linear, border-color 250ms linear;
    }

    .newsletter-button {
      background-color: white;
      color: $success;
    }
  }

  &.summary-page-3 {
    // background-image:
    //   radial-gradient(at 100% 0%, #E07A5F 0%, transparent 20%),
    //   radial-gradient(at 100% 0%, #8281B4 0%, transparent 40%);
    // transition: background-image $summaryPageColorTransitionDuration linear;

    .bg-3 {
      opacity: 1;
    }

    .button {
      @extend .is-info;
      transition: background-color $summaryPageColorTransitionDuration linear, border-color 250ms linear;
    }

    .newsletter-button {
      background-color: white;
      color: $info;
    }
  }

  &.summary-page-4 {
    // background-image:
    //   radial-gradient(at 100% 0%, #FFBE5F 0%, transparent 40%),
    //   radial-gradient(at 100% 0%, #8281B4 0%, transparent 40%);
    // transition: background-image $summaryPageColorTransitionDuration linear;

    .bg-4 {
      opacity: 1;
    }

    .button {
      @extend .is-warning;
      transition: background-color $summaryPageColorTransitionDuration linear, border-color 250ms linear;
    }

    .newsletter-button {
      background-color: white;
      color: $warning;
    }

    .make-donation-button {
      border-color: #E46E89 !important;

      &:hover {
        border-color: #ffffff !important;
        background-color: #E46E89 !important;
        color: #ffffff !important;
      }
    }
  }
}

.pages-section {
  position: relative;
  padding: 0;

  .total-num-of-pages {
    color: #000000;
  }

  .reading-progress-indicator {
    position: sticky;
    top: 60px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background: #ffffff;
    z-index: $baseZIndex + 1;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background: #000000;
      top: 14px;
      right: 20px;
      border-radius: 20px;
      @media (max-width: 767px) {
        top: 55px;
      }
    }
    @media (max-width: 767px) {
      top: 50px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @media (max-width: 729px) {
      top: 65px;
    }
    @media (max-width: 400px) {
      top: 80px;
    }

    // &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   bottom: 100%;
    //   left: 0;
    //   right: 0;
    //   background-color: #1C1D28;
    //   height: 1rem;
    // }

    .reading-progress-wrapper {
      hr {
        margin: 0 0 4px 0;
      }

      .page-progress {
        position: absolute;
        top: 6px;
        left: 10px;
        right: 10px;
        opacity: 0;
        transition: opacity 250ms;
        @media (max-width: 767px) {
          top: 50px;
        }

        &.active {
          opacity: 1;
        }
      }

      #page-1-progress {
        color: $danger;
      }

      #page-2-progress {
        color: $success;
      }

      #page-3-progress {
        color: $info;
      }

      #page-4-progress {
        color: $warning;
      }
    }

    .full-width-buttons {
      .button {
        cursor: pointer;
        color: white !important;
        font-weight: 800;
        font-size: 18px;
        z-index: 1;
        display: initial;
        height: 50px;
      }
      
      &.buttons-1 {
        .button {
          @extend .is-danger;
          background-color: $danger !important;
          transition: background-color 1000ms linear, border-color 250ms linear;
          padding-left: 1.7rem;
          padding-right: 1.7rem;
          height: 50px;
          line-height: 1.8;
        }
        .newsletter-button {
          border: 2px solid $danger !important;
          background-color: #ffffff !important;
          transition: background-color 1000ms linear, border-color 250ms linear;
          color: $danger !important;
        }
      }
      &.buttons-2 {
        .button {
          @extend .is-success;
          background-color: $success !important;
          transition: background-color $summaryPageColorTransitionDuration linear, border-color 250ms linear;
        }
        .newsletter-button {
          border: 2px solid $success !important;
          background-color: #ffffff !important;
          transition: background-color 1000ms linear, border-color 250ms linear;
          color: $success !important;
        }
      }
      &.buttons-3 {
        .button {
          @extend .is-info;
          background-color: $info !important;
          transition: background-color $summaryPageColorTransitionDuration linear, border-color 250ms linear;
        }
        .newsletter-button {
          border: 2px solid $info !important;
          background-color: #ffffff !important;
          transition: background-color 1000ms linear, border-color 250ms linear;
          color: $info !important;
        }
      }
    }
  }

  .main-title {
    padding: 0 30px;
  }

  .page {
    position: relative;
    margin-bottom: 100px;
    padding: 70px 100px 0 100px;

    &.narrow {
      padding: 70px 360px 0 360px;
      @media screen and (max-width: 1100px) {
        padding: 32px;
      }
    }

    .subtitle {
      font-weight: 900;
      font-size: 2.8125rem;
      line-height: calc(50/45);
      margin-bottom: 28px;
    }

    .emphasized {
      font-size: 1.143rem;
    }

    &:last-child {
      margin-bottom: 75px;
      padding-top: 100px;
    }

    .newsletter-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      margin-bottom: 0px;
      flex-wrap: wrap;

      input[type="text"] {
        background-color: transparent;
        font-size: 15px;
        padding: 20px 40px 22px 5px;
        color: #FFBE5F;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid black;
        box-shadow: none;
        width: 100%;
        @media (max-width: 1100px) {
          width: 100%;
        }

        &::placeholder {
          //color: #f4f1de73;
        }
      }

      .newsletter-button {
        border: 2px solid #ffffff;
        background-color: #E46E89;
        color: white;
        border-radius: 8px;
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        margin-top: 20px !important;
        height: 50px;
        padding-top: 10px !important;

        @media (max-width: 1100px) {
          margin-top: 40px !important;
        }
      }

      .newsletter-notification {
        align-self: center;    
      }
    }
  }

  .highlighted-section {
    padding: 30px;
    background-color: #F3F2EC;
    color: #8C8B88;
  }

  .star-img-container {
    position: relative;

    .star-img-wrapper {
      position: absolute;
      top: 10%;
      margin-left: 81%;
    }

    .star-img {
      width: 142px;
      max-width: 142px;
      height: 142px;
      max-height: 142px;
    }

    .star-txt {
      color: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 6px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      line-height: calc(19/16);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.75rem;
    }
  }

  .scrolldown-indicator {
    position: absolute;
    right: 12px;
    bottom: 14%;

    &.page-3 {
      bottom: 4%;
    }

    .page-num {
      font-size: 14px;
      font-weight: bold;
    }

    img {
      filter: invert(1);
    }
  }

  .petition-form {
    input.input {
      font-size: 0.875rem;
      height: 3.5em;
      padding-left: 0;
      padding-right: 0;
      color: #000000;

      &::placeholder {
        font-size: 0.875rem;
        color: #000000;
        opacity: 33%;
      }
    }

    .control {
      font-size: 0.875rem;
    }

    .button.is-large {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }
}

.background-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  overflow: hidden;
  z-index: 3;
  background-color: #ffffff;
  
  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background: radial-gradient(ellipse at center, rgba(28,29,40,0) 0%,rgba(28,29,40,0.75) 100%);
  // }
}

@keyframes noise {
  0% {
    transform: translateX(0px,0px); }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px,100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}

.background-pattern-animated {
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url('/img/noise.png') 0 0;
  background-size: 320px 320px;
  opacity: .15;
  animation: noise 1s steps(8,end) infinite both;
}

.social-media {
  a {
    background-color: #8281B2 !important;

    img {
      width: 20px;
      filter: invert(100%);
    }
  }
}

.background-static {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: $baseZIndex + 2;
  background-color: #0e0e0e;
  opacity: 0.7;
  display: none;
}

.arrow-right-button {
  padding: 0 12px;
  background-color: transparent;
  border-width: 3px;
}

.text-input {
  color: #F4F1DE;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #F4F1DE;
  border-radius: 0;

  &::placeholder {
    color: #f4f1de73;
  }
}

$checkbox-size: 25px;

input[type="checkbox"].checkbox {
  appearance: none;
  width: $checkbox-size;
  height: $checkbox-size;
  position: relative;
  vertical-align: bottom;
  border-color: #000000;
  border-width: 2px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    border: 2px solid #000000;
    opacity: 33%;
    font: {
      size: 0rem;
      weight: bolder;
    }
    line-height: $checkbox-size;
    text-align: center;
  }

  &:checked::after {
    content: "\02714";
    color: #8281B2;
    font-size: 16px;
    border-color: #8281B2;
    border-width: 2px;
    opacity: 100%;
  }
}

.button {
  font-family: "Acumin", sans-serif;
  border-width: 2px;
  border-radius: 8px;
  border-color: white !important;

  &.is-rounded-icon {
    padding: 8px;
    border-radius: 20px;
  }

  &:hover,
  &.selected {
    border-width: 2px;
    border-color: white !important;
  }
}

hr {
  background-color: #000000;
  margin-bottom: 4px;

  &.is-thin {
    height: 1px;
  }
}

.footer {
  background-color: transparent;
  padding: 30px 0 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  color: #000000;
  text-decoration: underline;

  &:hover {
    color: #8281B2;
  }
}

.success {
  .column {
    background: #272739;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    h4 {
      color: #FFBE5F;
      font-family: "Acumin", sans-serif;
      font-weight: 700;
      margin: 0;
    }

    h1 {
      font-size: 2.815rem;
      color: #F4F1DE;
      line-height: calc(50 / 45);
      font-weight: 700;
      font-family: "Acumin";
      margin: 0 0 20px;
    }

    p {
      margin: 0 0 1rem;
    }

    .button {
      @extend .is-danger;
      text-decoration: none;
    }
  }
}

.close-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.full-width-buttons {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  @media (max-width: 767px) {
    align-items: center;
  }
  
   > div {
    font-size: 28px;
    font-weight: bold;
    font-family: 'Acumin';
    font-style: italic;
    width: 33.33333337%;
    @media (max-width: 767px) {
      width: unset;
    }
   }
   > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
   }
   > div:nth-child(3) {
    text-align: right;
   }
}

.full-width-buttons-mobile {
  display: none;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: flex;
  }

  .bars div {
    width: 24px;
    height: 2px;
    background-color: black;
    margin: 4px 0;
  }
}
#mobileMenuButtons {
  display: none;
  width: 100%;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  hr {
    width: 100%;
  }
  .button {
    height: 50px;
    width: 100%;
  }
  .newsletter-button {
    border: 2px solid $danger !important;
    background-color: #ffffff !important;
    color: $danger !important;
  }
  .make-donation-button {
    border: 2px solid $danger !important;
    background-color: $danger !important;
    color: #ffffff !important;
    margin: 20px 0px 10px 0px;
  }
}

.popup {
  display: block !important;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 9999;
  border-radius: 10px;
  .bgs {
    border-radius: 10px;
  }
  .back-arrow{
    display: none !important;
  }
  >div:nth-child(2) {
    justify-content: flex-end !important;
  }
}

.no-before::before{
  display:none !important;
}

.dimmed {
  position: relative;
}

.dimmed:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.close-menu-button {
  display: none;
}

@import "responsive.scss";
