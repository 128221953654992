@media only screen and (max-width: 1024px) {
  .columns {
    margin-left: 0;
    margin-right: 0;
  }

  .summary-section {
    position: relative;
    max-height: max-content;
    padding: 32px;
    margin-bottom: 50px;
  }

  .pages-section {
    .page {
      margin-bottom: 100px;
      padding: 32px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .star-img-container {
      margin-top: 100px;

      .star-img-wrapper {
        top: -75px;
        margin-left: 30%;
      }
    }

    .scrolldown-indicator {
      display: none;
    }
  }

  .page-progress {
    .page-title {
      text-align: left !important;
      padding-left: 32px;
      margin-left: -12px;
    }
  }

  .subtitle.is-2 {
    font-size: 25px;
    font-weight: bold;
  }
}
